import * as React from 'react';
import PropTypes from 'prop-types';
//Mask
import NumberFormat from 'react-number-format';
//UI Components
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
//Icon
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
//Styles
import useStyles from './inputStyles';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { useContext } from 'react';
import { OrderContext } from '../../providers/orderContext';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      decimalScale={0}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="## #### ####"
    />
  );
});

const NacimientoFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  const customFormat = (inputValue) => {
    const day = inputValue.substring(0, 2);
    const month = inputValue.substring(2, 4);
    const year = inputValue.substring(4, 8);

    const dayVal = Number(day) > 31 ? '31' : day;
    const monthVal = Number(month) > 12 ? '12' : month;
    const yearVal = year.length === 4 ? Number(year) < 1920 ? '1920' : Number(year) > 2020 ? '2020' : year : year;

    return `${dayVal}/${monthVal}/${yearVal}`;
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        console.log('values', values);
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      format={customFormat} // Usamos la función de formato personalizado
      placeholder="DD/MM/AAAA"
      mask={['D', 'D', 'M', 'M', 'A', 'A', 'A', 'A']}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

let diners = '#### ###### ####';
let visaMastercard = false;
let american = false;

const CardFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    // format="#### ###### ####"
    />
  );
});

CardFormatCustom.displayName = 'CardFormatCustom';

CardFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const DocumentFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      allowedDecimalSeparators={false}
      isAllowed={(values) => {
        const { floatValue: value } = values;
        if(!value || (value < 99999999999 && value > 0)){
          if(!(/^[0-9]*$/).test(value) && value) return false;
          return true;
        }
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

DocumentFormatCustom.displayName = 'DocumentFormatCustom';

DocumentFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const MonthFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      allowLeadingZeros={true}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

MonthFormatCustom.displayName = 'MonthFormatCustom';

DocumentFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const CustomInput = ({
  label,
  errors,
  field,
  borders,
  width,
  lengthLimit,
  inputComponent,
  inputLabelProps,
  inputProps,
  tooltip,
  isValid,
  id,
  endAdornment,
  startAdornment,
  imageUrl,
  InputProps,
  ...rest
}) => {
  return (
    <TextField
      id={id}
      size='small'
      label={label}
      variant="filled"
      error={errors ? true : false}
      inputProps={{...InputProps}}
      InputLabelProps={{
        style: errors ? { color: '#f44336' } : { color: 'rgba(255,255,255,.8)' },
        ...inputLabelProps
      }}
      InputProps={{
        disableUnderline: true,
        sx: {
          borderRadius: '5px',
          border: errors ? '1px solid #f44336' : '1px solid #5B5C66',
          '&.Mui-focused': {
            backgroundColor: '#D9D9D926',
            border: errors ? '1px solid #f44336' : '1px solid #FFF',
            boxShadow: errors ? '0px 4px 60px 0px rgba(230, 50, 50, .2)' : '0px 4px 60px 0px #AA9C9C40',
          },
        },
        endAdornment: (isValid || errors) && !endAdornment ? (
          errors ? (
            <Tooltip
              title={errors.message}
              disableFocusListener
              disableTouchListener
            >
              <ErrorOutlineIcon color="error" style={{ fontSize: '21px' }} />
            </Tooltip>
          ) : (
            <CheckCircleOutlineIcon color="success" style={{ fontSize: '21px' }} />
          )
        ) : tooltip && !endAdornment ? (
          <Tooltip
            title={tooltip}
            disableFocusListener
            disableTouchListener
          >
            <HelpOutlineIcon sx={{ color: 'rgba(255,255,255,.6)' }} style={{ fontSize: '21px' }} />
          </Tooltip>
        ) : endAdornment && endAdornment,
        inputComponent: inputComponent ? inputComponent : null,
        startAdornment: startAdornment ? startAdornment : imageUrl ? (
          <Box sx={{ height: '100%', mt: 3, mr: 1 }}>
            <img
              width="auto"
              style={{ maxWidth: 50, maxHeight: 20 }}
              src={imageUrl}
              alt=""
            />
          </Box>
        ) : null,
        ...inputProps
      }}
      sx={{
        width: width,
        fontSize: '12px',
        m: 0,
        borderRadius: '5px',
        paddingBottom: 0,
      }}
      {...rest}
      {...field}
    />
  );
};

export const PhoneInput = ({ errors, field, touchedFields, ...rest }) => {
  return (
    <CustomInput
      label="Celular"
      errors={errors.celular}
      field={field}
      borders={{ topRight: 0, topLeft: 0, bottomLeft: 0, bottomRight: 0 }}
      inputComponent={NumberFormatCustom}
      isValid={touchedFields?.celular && !errors.celular && field.value !== ''}
      tooltip="Tu celular debe comenzar con 11 o 15"
      {...rest}
    />
  );
};

export const CustomInputDocument = ({
  label,
  errors,
  field,
  borders,
  width,
  lengthLimit,
  inputComponent,
  tooltip,
  isValid,
  id,
  imageUrl,
}) => {
  const classes = useStyles();
  return (
    <TextField
      id={id}
      label={label}
      variant="filled"
      className={classes.textField}
      error={errors ? true : false}
      InputProps={{
        className: classes.input,
        classes: {
          focused: classes.focused,
        },
        disableUnderline: true,
        sx: {
          border: errors
            ? '1px solid rgba(255, 0, 0, 0.5)'
            : '1px solid rgba(0, 0, 0, 0.1)',
          borderTopRightRadius: borders.topRight,
          borderTopLeftRadius: borders.topLeft,
          borderBottomLeftRadius: borders.bottomLeft,
          borderBottomRightRadius: borders.bottomRight,
        },
        endAdornment:
          isValid || errors ? (
            errors ? (
              <Tooltip
                title={errors.message}
                disableFocusListener
                disableTouchListener
              >
                {/* {isValid && <CheckCircleOutlineIcon color="success" />} */}
                <ErrorOutlineIcon color="error" />
              </Tooltip>
            ) : (
              <CheckCircleOutlineIcon color="success" />
            )
          ) : (
            tooltip && (
              <Tooltip
                title={tooltip}
                disableFocusListener
                disableTouchListener
              >
                <HelpOutlineIcon sx={{ color: 'rgba(10, 10, 10, 0.5)' }} />
              </Tooltip>
            )
          ),
        inputComponent: inputComponent ? inputComponent : null,
        startAdornment: imageUrl ? (
          <Box sx={{ height: '100%', mt: 3, mr: 1 }}>
            <img
              width="auto"
              style={{ maxWidth: 50, maxHeight: 20 }}
              src={imageUrl}
              alt=""
            />
          </Box>
        ) : null,
      }}
      inputProps={{
        maxLength: lengthLimit ? lengthLimit : 'none',
      }}
      sx={{ width: width, m: 0 }}
      {...field}
    />
  );
};

export const Nacimiento = ({ errors, field, touchedFields, id }) => {
  return (
    <>
      <CustomInput
        label="Nacimiento"
        id={id}
        errors={errors}
        field={field}
        width="100%"
        inputComponent={NacimientoFormatCustom}
        isValid={touchedFields}
        borders={{ topRight: 0, topLeft: 0, bottomLeft: 0, bottomRight: 0 }}
      />
    </>
  );
};

export const CardInput = ({ errors, field, touchedFields, imageUrl, id }) => {
  return (
    <>
      <CustomInput
        label="Número de la tarjeta de crédito o débito"
        id={id}
        errors={errors}
        field={field}
        borders={{ topRight: 10, topLeft: 10, bottomLeft: 0, bottomRight: 0 }}
        width="100%"
        inputComponent={CardFormatCustom}
        inputProps={{ format: diners, maxlength: 16 }}
        isValid={touchedFields}
        imageUrl={imageUrl}
        lengthLimit={18}
      />
    </>
  );
};

export const DNI = ({ errors, field, touchedFields }) => {
  return (
    <>
      <CustomInput
        label="Documento del Titular"
        id="form-checkout__identificationNumber"
        errors={errors?.payment?.identificationNumber}
        field={field}
        borders={{
          topRight: { xs: 0, md: 10 },
          topLeft: 0,
          bottomLeft: { xs: 10, md: 0 },
          bottomRight: 10,
        }}
        width={'100%'}
        isValid={touchedFields?.payment?.identificationNumber}
        lengthLimit={8}
        inputComponent={DocumentFormatCustom}
      />
    </>
  );
};

export const FacturaA = ({ errors, field, touchedFields }) => {

  const { checkout: { order } } = useContext(OrderContext);
  const merchantActual = order?.merchant;
  // const aceptaFacturaA = !merchantSinFacturaA.includes(merchantActual) && order?.['tipo-de-plan'] !== 'central';  
  const aceptaFacturaA = !!merchantActual?.acepta_factura_a;

  return (
    <>
      {aceptaFacturaA ?
        <FormControlLabel sx={{ width: '100%', color: '#fff' } }
          label={<p style={{ fontSize: '15px', width: '100%' }}>{'Solicitar factura A (sólo para Responsables Inscriptos)'}</p>} control={
            <Checkbox
              checked={field.value}
              disabled={!aceptaFacturaA}
              onChange={field.onChange}
              borders={{
                topRight: { xs: 0, md: 10 },
                topLeft: 0,
                bottomLeft: { xs: 10, md: 0 },
                bottomRight: 10,
              }}
              isValid={touchedFields?.factura_a}
              errors={errors}
              style={{ color: '#FFD300' }}
              // lengthLimit={8}
              // inputComponent={DocumentFormatCustom}
            />
          }
        />:<p style={{ fontSize: '15px', width: '100%', color: '#fff'  }}>En este momento no es posible solicitar facturas tipo A, por favor comunicarse con su sede</p>}
    </>
  );
};

export const CuitFacturaA = ({ errors, field, touchedFields }) => {
  return (
    <>
      <CustomInput
        label={'CUIT'}
        inputComponent={DocumentFormatCustom}
        errors={errors?.cuit}
        field={field}
        lengthLimit={12}
        isValid={touchedFields?.cuit}
        width={'100%'}
      />
    </>
  );
};
export const Month = ({ errors, field, touchedFields }) => {
  return (
    <>
      <CustomInput
        label="MM"
        id="form-checkout__cardExpirationMonth"
        errors={errors?.payment?.cardExpirationMonth}
        field={field}
        borders={{
          topRight: 0,
          topLeft: 0,
          bottomLeft: { xs: 10, md: 10 },
          bottomRight: 0,
        }}
        width={'100%'}
        lengthLimit={2}
        isValid={touchedFields?.payment?.cardExpirationMonth}
        inputComponent={MonthFormatCustom}
      />
    </>
  );
};

export const Year = ({ errors, field, touchedFields }) => {
  return (
    <>
      <CustomInput
        label="YY"
        id="form-checkout__cardExpirationYear"
        errors={errors?.payment?.cardExpirationYear}
        field={field}
        borders={{
          topRight: 0,
          topLeft: 0,
          bottomLeft: 0,
          bottomRight: { xs: 0, md: 0 },
        }}
        width={'100%'}
        lengthLimit={2}
        isValid={touchedFields?.payment?.cardExpirationYear}
        inputComponent={DocumentFormatCustom}
      />
    </>
  );
};

export const Cvv = ({ errors, field, touchedFields }) => {
  return (
    <>
      <CustomInput
        label="CVV/CVC"
        id="form-checkout__securityCode"
        errors={errors?.payment?.securityCode}
        field={field}
        borders={{
          topRight: 0,
          topLeft: 0,
          bottomLeft: { xs: 0, md: 0 },
          bottomRight: 10,
        }}
        width={'100%'}
        lengthLimit={4}
        tooltip="El código de seguridad o CCV son los 3 o 4 números adicionales que aparecen en el reverso o frente de tu tarjeta."
        isValid={touchedFields?.payment?.securityCode}
        inputComponent={MonthFormatCustom}
      />
    </>
  );
};

// export const FacturaA = ({ errors, field, touchedFields }) => {
//   return (
//     <>
//       <FormControlLabel
//         sx={{ width: '100%', color: '#fff' }}
//         label='Solicitar factura tipo A'
//         control={
//           <Checkbox
//             checked={field.value}
//             onChange={field.onChange}
//             isValid={touchedFields?.factura_a}
//             errors={errors}
//             style={{ color: '#FFD300' }}
//           />
//         }
//       />
//     </>
//   );
// };

export const DocumentInput = ({ errors, field, touchedFields, labelDocument, ...rest }) => {
  
  return (
    <>
      <CustomInput
        label={'Número'}
        inputComponent={labelDocument !== 'pasaporte' ? DocumentFormatCustom : null}
        errors={errors.documento}
        field={field}
        InputProps={labelDocument === 'pasaporte' && {  maxLength: 11 }}
        borders={{
          topRight: 0,
          topLeft: 0,
          bottomLeft: 0,
          bottomRight: 0,
        }}
        lengthLimit={12}
        isValid={touchedFields?.documento && !errors.documento && field.value !== ''}
        {...rest}
      />
    </>
  );
};
export const SelectInput = ({ defaultValue, fullWidth, label, field, disabled, data, errors, borders, id, variant = 'filled' }) => {
  const classes = useStyles();
  return (
    <Select
      fullWidth={fullWidth}
      disabled={disabled}
      defaultValue={defaultValue}
      label={label}
      id={id}
      className={classes.selectInput}
      sx={{
        borderRadius: '6px !important',
      }}
      inputProps={{
        sx: {
          borderRadius: '6px !important',
          paddingBottom: 0,
          border: errors ? '1px solid #f44336' : '1px solid #5B5C66',
        },
      }}
      {...field}
      variant={variant}
    >
      {data && data.map((d, index) => (
        <MenuItem key={index} value={d.value ? d.value : d.iso_nombre}>
          {d.iso_nombre}
        </MenuItem>
      ))}
    </Select>
  );
};

// export const CuitFacturaA = ({ errors, field, touchedFields }) => {
//   return (
//     <CustomInput
//       label={'CUIT'}
//       inputComponent={DocumentFormatCustom}
//       errors={errors?.cuit}
//       field={field}
//       lengthLimit={12}
//       isValid={touchedFields?.cuit}
//       width={'100%'}
//     />
//   );
// };

export const SelectInputDocument = ({ defaultValue, label, field, data, errors, borders, width }) => {
  const classes = useStyles();
  return (
    <Select
      defaultValue={defaultValue}
      label={label}
      className={classes.selectInput}
      inputProps={{
        className: classes.input,
        classes: {
          focused: classes.focused,
        },
        sx: {
          border: errors
            ? '1px solid #f44336'
            : '1px solid rgba(0, 0, 0, 0.1)',
          borderTopRightRadius: borders.topRight,
          borderTopLeftRadius: borders.topLeft,
          borderBottomLeftRadius: borders.bottomLeft,
          borderBottomRightRadius: borders.bottomRight,
        },
      }}
      {...field}
    >
      {data.map((d, index) => (
        <MenuItem key={index} value={d.name}>
          <span style={{ textTransform: 'capitalize' }}>{d.name}</span>
        </MenuItem>
      ))}
    </Select>
  );
};

// export const CustomInputDocument = ({
//   label,
//   errors,
//   field,
//   borders,
//   width,
//   lengthLimit,
//   inputComponent,
//   tooltip,
//   isValid,
//   id,
//   imageUrl,
// }) => {
//   const classes = useStyles();

//   return (
//     <TextField
//       id={id}
//       label={label}
//       variant="filled"
//       className={classes.textField}
//       error={errors ? true : false}
//       InputProps={{
//         className: classes.input,
//         classes: {
//           focused: classes.focused,
//         },
//         disableUnderline: true,
//         sx: {
//           border: errors
//             ? '1px solid rgba(255, 0, 0, 0.5)'
//             : '1px solid rgba(0, 0, 0, 0.1)',
//           borderTopRightRadius: borders.topRight,
//           borderTopLeftRadius: borders.topLeft,
//           borderBottomLeftRadius: borders.bottomLeft,
//           borderBottomRightRadius: borders.bottomRight,
//         },
//         endAdornment:
//           isValid || errors ? (
//             errors ? (
//               <Tooltip
//                 title={errors.message}
//                 disableFocusListener
//                 disableTouchListener
//               >
//                 {/* {isValid && <CheckCircleOutlineIcon color="success" />} */}
//                 <ErrorOutlineIcon color="error" />
//               </Tooltip>
//             ) : (
//               <CheckCircleOutlineIcon color="success" />
//             )
//           ) : (
//             tooltip && (
//               <Tooltip
//                 title={tooltip}
//                 disableFocusListener
//                 disableTouchListener
//               >
//                 <HelpOutlineIcon sx={{ color: 'rgba(10, 10, 10, 0.5)' }} />
//               </Tooltip>
//             )
//           ),
//         inputComponent: inputComponent ? inputComponent : null,
//         startAdornment: imageUrl ? (
//           <Box sx={{ height: '100%', mt: 3, mr: 1 }}>
//             <img
//               width="auto"
//               style={{ maxWidth: 50, maxHeight: 20 }}
//               src={imageUrl}
//               alt=""
//             />
//           </Box>
//         ) : null,
//       }}
//       inputProps={{
//         maxLength: lengthLimit ? lengthLimit : 'none',
//       }}
//       sx={{ width: width, m: 0 }}
//       {...field}
//     />
//   );
// };

